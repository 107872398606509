import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';

export const Header = styled.h1`
  margin-top: 5rem;
  margin-bottom: .5rem;

  font-family: ${({ theme: { fonts } }) => fonts.robotoBold};
  font-size: 3.2rem;
  color: ${({ theme: { colors } }) => colors.primary5};
  
  .number {
    font-size: 4.0rem;
    color: ${({ theme: { colors } }) => colors.primary4};
    margin-right: 2rem;
  }
`;
