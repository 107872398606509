import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';
import Layout from './Layout/Layout';

const Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.quicksandMedium}, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  
  position: relative;
  z-index: 0;
`;

const Footer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.2em;

  a {
    color: ${({ theme }) => theme.colors.primary};
    margin: 1em;
    text-transform: lowercase;
    text-decoration: none;
  }
`;

export const Wrapper: React.FC<{
  onClickLogout?();
}> = ({ children, onClickLogout }) => {
  return (
    <Layout>
      <Container>
        {children}
        <Footer>
          <a href="/">Strona główna</a>
          <span>•</span>
          <a href="https://jbsolutions.pl/regulamin">Regulamin</a>
          <span>•</span>
          <a href="https://jbsolutions.pl/polityka-prywatnosci">Polityka Prywatności</a>
          {onClickLogout && (
            <>
              <span>•</span>
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  onClickLogout();
                }}
              >
                Wyloguj
              </a>
            </>
          )}
        </Footer>
      </Container>
    </Layout>
  );
};
