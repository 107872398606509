import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';
import { Header } from '../components/Header';
import { Img } from '../components/Img';
import { Wrapper } from '../components/Wrapper';

const imageSource = require('./../components/assets/undraw_blank_canvas_3rbb.png');

const Warning = styled.div`
  font-size: 2rem;
  border: 5px solid #FFCB00;
  border-radius: 10rem;
  padding: 3rem 5rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const IndexPage = () => (
  <Wrapper>
    <Header>
      <span className="number">4.</span>
      Nie udało się
    </Header>
    <Warning>
      Niestety nie udało się przekonwertować tego wyciągu bankowego. <br/><br/>
      Jeżeli korzystałeś z wykrywania automatycznego, spróbuj wybrać ręcznie typ wyciągu. <br/><br/>
      Jeśli nadal się nie udało, prześlij plik na <a href="mailto:pomoc@fakturuj.to">pomoc@fakturuj.to</a> a my
      spróbujemy zrobić to za Ciebie.
    </Warning>
    <Img src={imageSource}/>
  </Wrapper>
);

export default IndexPage;
